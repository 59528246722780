import { actionUrl } from "@/apiClient";
export const moduleSlug = 'loyalty';
export const moduleAuth = 'Loyalty';
import LoyaltyPicker from "./components/LoyaltyPicker.vue";
export const children = [
  {
    path: '',
    component: () => import('./pages/LoyaltyDashboard.vue'),
    meta: {
      title: 'Loyalty Dashboard',
      linkTitle: 'Dashboard',
      linkIcon: 'home',
      showInNav: true,
      permission: "Loyalty.Dashboard",
    }
  },
  {
    path: 'freebie/overview',
    component: () => import('./pages/LoyaltyFreebieOverview.vue'),
    meta: {
      title: 'Freebies',
      linkIcon: 'database',
      showInNav: true,
      permission: "Loyalty.FreebiesReport",
    }
  },
  {
    path: 'tiers/report',
    component: () => import('./pages/LoyaltyTierWiseReport.vue'),
    meta: {
      title: 'Tiers',
      linkIcon: 'file-text',
      showInNav: true,
      permission: "Loyalty.TiersReport",
    }
  },
  {
    path: 'points/report',
    component: () => import('./pages/LoyaltyPointsReport.vue'),
    meta: {
      title: 'Points',
      linkIcon: 'file-powerpoint-o',
      showInNav: true,
      permission: "Loyalty.PointsReport",
    }
  },
  {
    path: 'coupon/report',
    component: () => import('./pages/LoyaltyCouponReport.vue'),
    meta: {
      title: 'Coupons',
      linkIcon: 'gift',
      showInNav: true,
      permission: "Loyalty.CouponsReport",
    }
  },
  {
    path: 'approve-cards',
    component: () => import('./pages/LoyaltyApprovalPlatform.vue'),
    meta: {
      title: 'Signups',
      linkIcon: 'credit-card',
      showInNav: true,
      permission: "Loyalty.CardApproval",
    }
  },
  {
    path: 'cards-payments',
    component: () => import('./pages/LoyaltyCardsPayments.vue'),
    meta: {
      title: 'Payments',
      linkIcon: 'credit-card',
      showInNav: true,
      permission: "Loyalty.CardPaymentReport",
    }
  },
  {
    path: 'challenges',
    component: () => import('./pages/LoyaltySchemeChallenge.vue'),
    meta: {
      title: 'Challenges',
      linkIcon: 'bullseye',
      showInNav: true,
      permission: "Loyalty.ChallengesReport",
    }
  },
  {
    path: 'subscriptions',
    component: () => import('./pages/LoyaltyCardSubScription.vue'),
    meta: {
      title: 'Subscriptions',
      linkIcon: 'fa fa-cc"',
      showInNav: true,
      permission: "Loyalty.Subscriptions",
    }
  },
  {
    path: 'tiers/transitions',
    component: () => import('./pages/Transitions.vue'),
    meta: {
      showInNav: false,
    }
  },
  {
    path: 'config/programs',
    component: () => import('./pages/LoyaltyPrograms.vue'),
    meta: {
      title: 'programs',
      linkIcon: '',
      showInNav: false,
    }
  },
  {
    path: 'config/programs/create',
    component: () => import('./pages/programCreation/CreateProgram.vue'),
    meta: {
      title: 'Program Create',
      linkIcon: '',
      showInNav: false,
    }
  },
  {
    path: 'config/programs/edit/:id',
    component: () => import('./pages/programCreation/CreateProgram.vue'),
    meta: {
      title: 'Program Update',
      linkIcon: '',
      showInNav: false,
    }
  },
  {
    path: 'config',
    component: () => import('@/components/Configuration/Configurations.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Configurations',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Dashboard",
    }
  },
  {
    path: 'config/activities',
    component: () => import('./pages/LoyaltyActivity.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activity',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Config.Activities",
    },
  },
  {
    path: 'config/activities/create',
    component: () => import('./pages/LoyaltyActivityCreateOrEdit.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activity',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Config.Activities",
    },
  },
  {
    path: 'config/activities/update',
    component: () => import('./pages/LoyaltyActivityCreateOrEdit.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activity',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Config.Activities",
    },
  },
  {
    path: 'activities/overview',
    component: () => import('./pages/LoyaltyActivityOverview.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activities Overview',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Activities",
    },
  },
  {
    path: 'activities/participationInsights',
    component: () => import('./pages/LoyaltyActivityInsights.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Loyalty Activities Insights',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: "Loyalty.Activities",
    },
  },
  {
    path: "",
    meta: {
      title: "Activities",
      linkTitle: "Goals",
      linkIcon: "tasks",
      showInNav: true,
      excludeInRoute: true,
      menu: [
        {
          path: "activities/overview",
          title: 'Overview',
          permission: "Loyalty.Activities",

        },
        {
          path: "activities/participationInsights",
          title: 'Participants',
          permission: "Loyalty.Activities",
        },
      ],
    },
  },
];
export const quickLinks = [
  {
    name: 'Loyalty',
    link: actionUrl + 'merchant/:id/loyalty',
    permission: "Loyalty.Config.Loyalty",
  },
  {
    name: 'Multi-Loyalty',
    link: actionUrl + 'merchant/:id/multi-loyalty',
    permission: "Loyalty.Config.Loyalty",
  },
  {
    name: 'Calender Automation',
    link: actionUrl + 'merchant/:id/calendar-automation',
    url: '',
    permission: "Loyalty.Config.CalendarAutomation",
  },
  {
     name: 'Fraud Detection',
     link: actionUrl + 'merchant/:id/fraud-detection',
     permission: "Loyalty.Config.FraudDetection",
  },
  {
    name: 'Sms',
    link: actionUrl + 'merchant/:id/sms-settings',
    permission: "Loyalty.Config.SmsTransactionSettings",
  },
  {
    name: 'Transaction Settings',
    link: actionUrl + 'merchant/:id/txn-settings',
    permission: "Loyalty.Config.SmsTransactionSettings",
  },
  {
    name: 'Configurations',
    link: `/${moduleSlug}/config`,
    new: true,
    brandFlag: 'enableConfigurations',
    permission: "Loyalty.Dashboard",
  },
  {
    name: 'Loyalty Programs',
    link: `/${moduleSlug}/config/programs`,
    new: true,
    permission: "Loyalty.Dashboard",
  },
  {
    name: 'Loyalty Activity',
    link: `/${moduleSlug}/config/activities`,
    new: true,
    permission: "Loyalty.Dashboard",
  },
];

export const picker = LoyaltyPicker;