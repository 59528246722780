export const urls = {
    getOrderHistoryUrl: "gifting/api/reports/order-history",
    getOrderInsightsUrl: "gifting/api/reports/order-insights",
    getGiftConfigUrl:"gifting/api/reports/order-config",
    getHistoryConfigUrl:"gifting/api/reports/transactions-config",

    // denomination
    getConfigurationDenominationsUrl: "gifting/api/configuration/get-configuration",
    setDenominationUrl: "gifting/api/configuration/set-denomination-config",

    // logo 
    getLogoConfigurationUrl: "gifting/api/configuration/get-logo-configuration",
    setLogoConfigurationUrl: "gifting/api/configuration/set-logo-configuration",
    updateLogoUrl: "gifting/api/configuration/update-logo",
    deleteLogoUrl: "gifting/api/configuration/delete-logo",

    //category
    getCategoryListUrl: "gifting/api/category/list",
    setCategoryUrl: "gifting/api/category/create",
    updateCategoryUrl: "gifting/api/category/update",
    deleteCategoryUrl: "gifting/api/category/delete",

    // Offers
    getOffersListUrl: "gifting/api/offer/list",
    updateOfferUrl: "gifting/api/offer/update",
    addOfferUrl: "gifting/api/offer/create",
    deleteOfferUrl: "gifting/api/offer/delete",

    //themes
    getThemesListUrl: "gifting/api/theme/categoryWiseThemes",
    updateThemeUrl: "gifting/api/theme/update",
    addThemeUrl: "gifting/api/theme/add",
    deleteThemeUrl: "gifting/api/theme/delete",

    //terms and instructions
    getTermsAndInstructionsUrl: "gifting/api/configuration/get-terms-and-instructions",
    setTermsAndInstructions:"gifting/api/configuration/set-terms-and-instructions",

    //redemtion Panel 
    getMerchantlistUrl: "business/api/merchants",
    getCardBalanceUrl: "gifting/api/redemption-panel/get-card-balance",
    applyGiftCardUrl: "gifting/api/redemption-panel/apply-gift-card",
    resendOtpUrl: "gifting/api/redemption-panel/resend-otp",
    validateOtpUrl: "gifting/api/redemption-panel/validate-otp",
    confirmRedeemUrl: "gifting/api/redemption-panel/confirm-redemption",
    
    /**gifting transction */
    getTransactionHistoryUrl:"gifting/api/reports/transaction-history",
    getTransactionInsightsUrl:"gifting/api/reports/transaction-insights",
    /** gift cards */
    getGiftCardUrl:"gifting/api/reports/gift-cards",
    getGiftcardInsightsUrl:"gifting/api/reports/gift-card-insights",
 
    
    // gifting COnfigurations
    getGiftingListUrl: "gifting/api/gifting-list",
    createGiftingConfigUrl: "gifting/api/create-gifting-config",
    getCurrencyListUrl : "gifting/api/get-currency-list",
}